import React, { useEffect, useState } from "react";
import PlusIcon from "../../assets/plusicon.png";
import dayjs from "dayjs";
import checkMarkArrow from "../../assets/check2.svg";
import DownArrow from "../../assets/dropdown-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../common/Spinner";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import delIcon from "../../assets/delIcon.png";
import NoteModal from "./modals/NoteModal";
import arrowHead from "../../assets/Play icon.svg";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function Day({
  data,
  date,
  change,
  setChange,
  statusColor,
  setStatusColor,
  statusName,
  setStatusName,
  dayNumber,
  divColor,
  slotsData,
  callSlotsApi,
  setCallSlotsApi,
  statusData,
}) {
  const navigate = useNavigate();
  const today = dayjs();
  const formattedToday = today.format("YYYY-MM-DD");
  const [anchorEl, setAnchorEl] = useState(null);
  const [value] = useState(dayjs(formattedToday));

  const [tableData, setTableData] = useState(null);
  const [isLoading] = useState(false);
  const [searchText] = useState(null);

  const [editingMeetingId, setEditingMeetingId] = useState(null);
  const [editingMeetingTextId, setEditingMeetingTextId] = useState(null);
  const [editingMeetingText, setEditingMeetingText] = useState({
    time_slot: "",
    meeting_with: "",
    cancel_person: "",
    status: "",
    bg_color: "",
    meeting_note: "",
  });
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  // const[note,setNote]=useState("");
  const [agentId, setAgentId] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [notee, setNote] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const [isEditingTooltip, setIsEditingTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [activeMeetingId, setActiveMeetingId] = useState(null);

  const handleMouseEnter = (meetingId, meetingNote) => {
    setActiveMeetingId(meetingId);
    setTooltipContent(meetingNote);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (!isTooltipActive) {
      setShowTooltip(false);
      setActiveMeetingId(null);
    }
  };

  const isTooltipVisible = (meetingId) =>
    showTooltip && activeMeetingId === meetingId;

  const handleTooltipEnterPress = (event) => {
    if (event.key === "Enter") {
      if (tooltipContent && tooltipContent.length > 200) {
        toast.error(
          "The Meeting Note must not be greater than 200 characters",
          {
            position: "top-center",
            autoClose: 2500,
          }
        );
        return; // Exit function if meeting person is not provided
      }
      // saveTooltipContent();
      setIsEditingTooltip(false);
      setShowTooltip(false);
      handleAddNote(meetingId, tooltipContent, agentId, timeSlot, date);
      // console.log("Saved tooltip content:", tooltipContent);
    }
  };

  const saveTooltipContent = () => {
    setIsEditingTooltip(false);
    setShowTooltip(false);
    handleAddNote(meetingId, tooltipContent, agentId, timeSlot, date);
    // console.log("Saved tooltip content:", tooltipContent);
  };
  const handleAddNote = (meetingId, note, agentId, timeSlot, date) => {
    if (note && note.length > 200) {
      toast.error("The Meeting Note must not be greater than 200 characters", {
        position: "top-center",
        autoClose: 2500,
      });
      return; // Exit function if meeting person is not provided
    }
    // if (e.key === "Enter") {
    const agentIndex = tableData.findIndex((agent) =>
      agent.meetings.some((meeting) => meeting._id === meetingId)
    );
    if (agentIndex !== -1) {
      const meetingIndex = tableData[agentIndex].meetings.findIndex(
        (meeting) => meeting._id === meetingId
      );

      if (meetingIndex !== -1) {
        if (meetingId.includes("-")) {
          // If _id contains a hyphen, create a new meeting
          if (date.add(-1, "day").isBefore(today, "day")) {
            setEditingMeetingTextId(null);
            toast.error("You cannot create a meeting for a past date");
            return;
          }
          const [hours, minutes] = timeSlot.split(":").map(Number);
          if (hours < 7 || hours > 19) {
            toast.error("Time slot must be between 07:00 and 19:00.", {
              position: "top-center",
              autoClose: 2500,
            });
            return;
          }
          if (hours === 19 && minutes > 0) {
            toast.error("Time slot must be between 07:00 and 19:00.", {
              position: "top-center",
              autoClose: 2500,
            });
            return;
          }
          createNewMeetingNote({ note, agentId, timeSlot, date });
        } else {
          // If _id does not contain a hyphen, update the existing meeting
          updateExistingMeetingNote({ note, meetingId, timeSlot, date });
        }
      }
    }
  };

  const createNewMeetingNote = async ({ note, agentId, timeSlot, date }) => {
    const token = localStorage.getItem("token");

    let body = {
      agent_id: agentId,
      time_slot: timeSlot,
      status: statusName !== "Clear" ? statusName : "",
      // meeting_with: data?.meeting_with,
      // cancel_person: data?.cancel_person,
      // bg_color: data?.bg_color,
      meeting_date: date,
      meeting_note: note,
    };

    try {
      // Call create-meeting API if any of these fields are filled
      const response = await axios.post(`${BASE_URL}/create-meeting`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.success === 1) {
        await axios.post(
          `${BASE_URL}/create-slot`,
          { time: data?.time_slot },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success(response?.data?.message);
        setCallSlotsApi(!callSlotsApi);
        getMeetingData();
        setChange(!change);
        //removed "Clear" from below
        setStatusName("");
        setStatusColor("");
      }
    } catch (error) {
      setStatusColor("");
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const updateExistingMeetingNote = async ({
    note,
    meetingId,
    timeSlot,
    date,
  }) => {
    const token = localStorage.getItem("token");

    let body = {
      meeting_id: meetingId,
      meeting_date: date,
      meeting_note: note,
      time_slot: timeSlot,
    };

    try {
      const response = await axios.put(`${BASE_URL}/update-meeting`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.success === 1) {
        await axios.post(
          `${BASE_URL}/create-slot`,
          { time: data?.time_slot },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success(response?.data?.message);
        setCallSlotsApi(!callSlotsApi);
        getMeetingData();
        setChange(!change);
        //removed "Clear" from below
        setStatusName("");
        setStatusColor("");
      }
    } catch (error) {
      setStatusColor("");
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const handleDouble = (meetingId) => {
    setAnchorEl(null);
    // console.log(meetingId, "double");
    setEditingMeetingTextId(meetingId);
    const meeting = tableData
      .flatMap((agent) => agent.meetings)
      .find((meeting) => meeting._id === meetingId);
    // console.log(meeting, "loop testing");
    setEditingMeetingText({
      ...meeting,
      status: meeting.status || statusData[statusData?.length - 1]?.status_name,
      bg_color:
        meeting.bg_color || statusData[statusData?.length - 1]?.status_color,
    });

    // const agentContainingMeeting = tableData.find((agent) =>
    //   agent.meetings.some((meeting) => meeting._id === meetingId)
    // );
    // const agentIndex = tableData.findIndex(
    //   (agent) =>
    //     agent.agent_name === agentContainingMeeting.agent_name &&
    //     agent.agent_id === agentContainingMeeting.agent_id
    // );
    // console.log(agentIndex, "agentIndex");
  };

  // Function to handle hover over a row
  const handleRowHover = (meetingId) => {
    setEditingMeetingId(meetingId);
  };

  // Function to handle leaving hover over a row
  const handleRowLeave = () => {
    setEditingMeetingId(null);
  };

  const handleAdd = (meetingId) => {
    setAnchorEl(null);
    const uuid = uuidv4();

    // Find the agent that contains the meeting with the given meetingId
    const agentContainingMeeting = tableData.find((agent) =>
      agent.meetings.some((meeting) => meeting._id === meetingId)
    );

    if (agentContainingMeeting) {
      // Find the index of the meeting with the given meetingId
      const meetingIndex = agentContainingMeeting.meetings.findIndex(
        (meeting) => meeting._id === meetingId
      );

      if (meetingIndex !== -1) {
        // Create a new meeting entry
        const newMeeting = {
          _id: uuid,
          agent_name: agentContainingMeeting.agent_name,
          agent_id: agentContainingMeeting.agent_id,
          meeting_with: "", // You can set the meeting_with, time_slot, status, and other properties as needed
          time_slot: "",
          status: statusData[statusData?.length - 1]?.status_name
            ? statusData[statusData?.length - 1]?.status_name
            : "",
          bg_color: statusData[statusData?.length - 1]?.status_color
            ? statusData[statusData?.length - 1]?.status_color
            : "",
          meeting_note: agentContainingMeeting.meeting_note,
        };

        // Find the index of the agent using agent_id
        const agentIndex = tableData.findIndex(
          (agent) => agent.agent_id === agentContainingMeeting.agent_id
        );

        // Update the state to add the new meeting entry after the meeting with the given meetingId
        setTableData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[agentIndex].meetings.splice(
            meetingIndex + 1,
            0,
            newMeeting
          );
          return updatedTableData;
        });

        // console.log(
        //   "New meeting added for agent:",
        //   agentContainingMeeting.agent_name
        // );
      } else {
        console.log("Meeting not found.");
      }
    } else {
      console.log("Agent not found.");
    }
  };

  const handleAdddd = (agentId) => {
    const uuid = uuidv4();
    // Find the agent by ID
    const agent = tableData.find((agent) => agent.agent_id === agentId);

    if (agent) {
      // Create a new meeting entry
      const newMeeting = {
        agent_name: agent.agent_name,
        agent_id: agent.agent_id,
        meeting_with: "",
        time_slot: "",
        status: "",
        bg_color: "",
        _id: uuid,
        meeting_note: "",
      };

      // Update the state to add the new meeting entry at the top
      setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData.forEach((a) => {
          if (a.agent_id === agentId) {
            a.meetings.unshift(newMeeting);
          }
        });
        return updatedTableData;
      });

      console.log("New meeting added for agent:", agent.agent_name);
    } else {
      console.log("Agent not found.");
    }
  };
  //*********** OLD DELETE FUNCTION FOR BACKUP  */
  // const handleDelete = (meetingId, time) => {
  //   setAnchorEl(null);
  //   if (meetingId.includes("-")) {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         try {
  //           const response = await axios.post(
  //             `${BASE_URL}/delete-slots`,
  //             { time },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
  //               },
  //             }
  //           );

  //           if (response?.data?.status == 1) {
  //             Swal.fire({
  //               title: "Deleted!",
  //               text: "Time slot has been deleted successfully",
  //               icon: "success",
  //               confirmButtonColor: "#336ba6",
  //             });
  //             setChange((prevChange) => !prevChange);

  //             // Handle the deletion in the UI if necessary, similar to below
  //             const agentContainingMeeting = tableData.find((agent) =>
  //               agent.meetings.some((meeting) => meeting._id === meetingId)
  //             );

  //             if (agentContainingMeeting) {
  //               const meetingIndex = agentContainingMeeting.meetings.findIndex(
  //                 (meeting) => meeting._id === meetingId
  //               );

  //               if (meetingIndex !== -1) {
  //                 const agentIndex = tableData.findIndex(
  //                   (agent) =>
  //                     agent.agent_id === agentContainingMeeting.agent_id
  //                 );

  //                 setTableData((prevTableData) => {
  //                   const updatedTableData = [...prevTableData];
  //                   updatedTableData[agentIndex].meetings.splice(
  //                     meetingIndex,
  //                     1
  //                   );
  //                   return updatedTableData;
  //                 });
  //               }
  //             }
  //           } else {
  //             if (response?.data?.status == 1) {
  //               toast.success(response?.data?.message);
  //             } else {
  //               toast.error(response?.data?.message);
  //             }
  //           }
  //         } catch (error) {
  //           toast.error("Failed to delete time slot");
  //         }
  //       }
  //     });
  //   } else {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         try {
  //           const response = await axios.delete(`${BASE_URL}/delete-meeting`, {
  //             headers: {
  //               Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             },
  //             data: { meeting_id: meetingId },
  //           });

  //           if (response?.data?.success === 1) {
  //             Swal.fire({
  //               title: "Deleted!",
  //               text: response?.data?.message,
  //               icon: "success",
  //               confirmButtonColor: "#336ba6",
  //             });
  //             getMeetingData();
  //             setChange((prevChange) => !prevChange);
  //           } else {
  //             toast.error(response?.data?.message);
  //           }
  //         } catch (error) {
  //           toast.error("Failed to delete meeting");
  //         }
  //       }
  //     });
  //   }
  // };

  const handleDelete = (meetingId, time) => {
    setAnchorEl(null);
    if (meetingId.includes("-")) {
      Swal.fire({
        title: "Error!",
        text: "There is no meeting in this time slot to delete, Please add a meeting first!",
        icon: "error",
        confirmButtonColor: "#336ba6",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.delete(`${BASE_URL}/delete-meeting`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              data: { meeting_id: meetingId },
            });

            if (response?.data?.success === 1) {
              Swal.fire({
                title: "Deleted!",
                text: response?.data?.message,
                icon: "success",
                confirmButtonColor: "#336ba6",
              });
              getMeetingData();
              setChange((prevChange) => !prevChange);
            } else {
              toast.error(response?.data?.message);
            }
          } catch (error) {
            toast.error("Failed to delete meeting");
          }
        }
      });
    }
  };

  const handleDeleteTimeSlot = (meetingId, time) => {
    setAnchorEl(null);
    if (meetingId.includes("-")) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              `${BASE_URL}/delete-slots`,
              { time },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            if (response?.data?.status === "1") {
              Swal.fire({
                title: "Deleted!",
                text: "Time slot has been deleted successfully",
                icon: "success",
                confirmButtonColor: "#336ba6",
              });
              setChange((prevChange) => !prevChange);

              setCallSlotsApi(!callSlotsApi);

              // Handle the deletion in the UI if necessary, similar to below
              const agentContainingMeeting = tableData.find((agent) =>
                agent.meetings.some((meeting) => meeting._id === meetingId)
              );

              if (agentContainingMeeting) {
                const meetingIndex = agentContainingMeeting.meetings.findIndex(
                  (meeting) => meeting._id === meetingId
                );

                if (meetingIndex !== -1) {
                  const agentIndex = tableData.findIndex(
                    (agent) =>
                      agent.agent_id === agentContainingMeeting.agent_id
                  );

                  setTableData((prevTableData) => {
                    const updatedTableData = [...prevTableData];
                    updatedTableData[agentIndex].meetings.splice(
                      meetingIndex,
                      1
                    );
                    return updatedTableData;
                  });
                }
              }
            } else {
              // toast.error(response?.data?.message);
              Swal.fire({
                title: "Error!",
                text: response?.data?.message,
                icon: "error",
                confirmButtonColor: "#336ba6",
              });
            }
          } catch (error) {
            toast.error("Failed to delete time slot");
          }
        }
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: "A meeting is assocised with this time slot, please delete or re-schedule that meeting first!",
        icon: "error",
        confirmButtonColor: "#336ba6",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();

      // Call a function or perform an action when Enter key is pressed
      //   console.log("Enter key pressed");
      // For example, you can submit a form or trigger some action here
    }
  };

  const handleSave = () => {
    // if (e.key === "Enter") {
    const agentIndex = tableData.findIndex((agent) =>
      agent.meetings.some((meeting) => meeting._id === editingMeetingText._id)
    );
    if (agentIndex !== -1) {
      const meetingIndex = tableData[agentIndex].meetings.findIndex(
        (meeting) => meeting._id === editingMeetingText._id
      );

      if (meetingIndex !== -1) {
        // Validate time format
        if (!isValidTime(editingMeetingText.time_slot)) {
          toast.error(
            "Invalid time format. Please enter time in HH:MM format (e.g., 09:10).",
            {
              position: "top-center",
              autoClose: 2500, // Auto close after 5 seconds (5000 milliseconds)
            }
          );

          // alert("Invalid time format. Please enter time in HH:MM format.");
          return; // Exit function if time is invalid
        }
        const [hours, minutes] = editingMeetingText.time_slot
          .split(":")
          .map(Number);
        if (hours < 7 || hours > 19) {
          toast.error("Time slot must be between 07:00 and 19:00.", {
            position: "top-center",
            autoClose: 2500,
          });
          return;
        }
        if (hours === 19 && minutes > 0) {
          toast.error("Time slot must be between 07:00 and 19:00.", {
            position: "top-center",
            autoClose: 2500,
          });
          return;
        }

        if (
          editingMeetingText.meeting_with &&
          editingMeetingText.meeting_with.length > 30
        ) {
          toast.error(
            "The Meeting person name must not be greater than 30 characters",
            {
              position: "top-center",
              autoClose: 2500,
            }
          );
          return; // Exit function if meeting person is not provided
        }
        // if (
        //   editingMeetingText.meeting_note &&
        //   editingMeetingText.meeting_note.length > 50
        // ) {
        //   toast.error(
        //     "The Meeting Note must not be greater than 50 characters",
        //     {
        //       position: "top-center",
        //       autoClose: 2500,
        //     }
        //   );
        //   return; // Exit function if meeting person is not provided
        // }
        if (
          editingMeetingText.cancel_person &&
          editingMeetingText.cancel_person.length > 30
        ) {
          toast.error(
            "The Cancelled person name must not be greater than 30 characters",
            {
              position: "top-center",
              autoClose: 2500,
            }
          );
          return; // Exit function if meeting person is not provided
        }

        if (editingMeetingText._id && editingMeetingText._id.includes("-")) {
          // If _id contains a hyphen, create a new meeting
          if (date.add(-1, "day").isBefore(today, "day")) {
            setEditingMeetingTextId(null);
            toast.error("You cannot create a meeting for a past date");
            return;
          }
          createNewMeeting(editingMeetingText);
        } else {
          // If _id does not contain a hyphen, update the existing meeting
          updateExistingMeeting(editingMeetingText);
        }

        // Reset the editingMeetingText state
        setEditingMeetingText({
          _id: "",
          time_slot: "",
          meeting_with: "",
          cancel_person: "",
          status: "",
        });
      }
    }

    setEditingMeetingTextId(null);
  };

  // Function to validate time format (HH:MM)
  const isValidTime = (time) => {
    const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(time);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Update the formData state with the new value
    setEditingMeetingText({
      ...editingMeetingText,
      [name]: value,
    });
  };

  const getMeetingData = async () => {
    // const uuid = uuidv4();
    // setIsLoading(true);

    const slots = slotsData ? slotsData?.data.map((item) => item.slot) : [];

    // Function to generate time slots from 7 AM to 7 PM
    // const generateTimeSlots = () => {
    //   const slots = [];
    //   for (let hour = 7; hour <= 19; hour++) {
    //     const slot = `${hour.toString().padStart(2, "0")}:00`;
    //     slots.push(slot);
    //   }
    //   return slots;
    // };
    // const allTimeSlots = generateTimeSlots();
    // console.log("HERE ARE TIME SLOTS GENERATED:", allTimeSlots);
    // Helper function to convert time slot to a sortable format

    // Helper function to sort time slots correctly

    try {
      // Merge the generated time slots with those from the data
      const combinedTimeSlots = Array.from(new Set([...slots])).sort();

      // const allTimeSlots = getAllTimeSlots(response?.data?.result);
      const normalizeData = (agentsData, combinedTimeSlots) => {
        return agentsData.map((agent) => {
          const normalizedMeetings = combinedTimeSlots.map((slot) => {
            const meeting = agent.meetings.find(
              (meeting) => meeting.time_slot === slot
            );
            return meeting
              ? meeting
              : {
                  agent_name: agent.agent_name,
                  agent_id: agent.agent_id,
                  meeting_with: "",
                  time_slot: slot,
                  status: "",
                  _id: uuidv4(),
                  meeting_note: "",
                };
          });
          return { ...agent, meetings: normalizedMeetings };
        });
      };
      // const day = dayNumber
      const normalizedData = normalizeData(data[dayNumber], combinedTimeSlots);
      // console.log(allTimeSlots, normalizedData, "slotssss");

      // setTableData(response?.data?.result);
      setTableData(normalizedData);
      // getNotification();

      // toast.success(response?.data?.message);
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        // console.log(error, "YEH CHLA HAI");
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getMeetingData();
    // eslint-disable-next-line
  }, [value, searchText]);

  // const createNewMeeting = async (data) => {
  //   const token = localStorage.getItem("token");
  //   // console.log("HERE IS THE VALUE OF DATE:", value);
  //   let body = {
  //     agent_id: data?.agent_id,
  //     time_slot: data?.time_slot,
  //     status: statusName !== "Clear" ? statusName : "",
  //     meeting_with: data?.meeting_with,
  //     cancel_person: data?.cancel_person,
  //     // bg_color: data?.bg_color,
  //     meeting_date: date,
  //     meeting_note: data?.meeting_note,
  //   };
  //   try {
  //     const response = await axios.post(`${BASE_URL}/create-meeting`, body, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response?.data?.success === 1) {
  //       await axios.post(
  //         `${BASE_URL}/create-slot`,
  //         { time: data?.time_slot },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  //       toast.success(response?.data?.message);
  //       getMeetingData();
  //       setChange(!change);
  //       setStatusName("Clear");
  //       setStatusColor("");
  //     }
  //   } catch (error) {
  //     setStatusColor("");
  //     if (error?.response?.data) {
  //       if (
  //         error.response.data.message === "Session expired please login again"
  //       ) {
  //         // Handle session expiration
  //         localStorage.removeItem("token");
  //         navigate("/");
  //         toast.error(error?.response?.data?.message);
  //       } else {
  //         toast.error(error?.response?.data?.message);
  //       }
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };

  const createNewMeeting = async (data) => {
    const token = localStorage.getItem("token");

    let body = {
      agent_id: data?.agent_id,
      time_slot: data?.time_slot,
      status: statusName !== "Clear" ? statusName : "",
      meeting_with: data?.meeting_with,
      cancel_person: data?.cancel_person,
      // bg_color: data?.bg_color,
      meeting_date: date,
      meeting_note: data?.meeting_note,
    };

    try {
      // Check if meeting_with, status, cancel_person, and meeting_note are all empty
      const isEmpty =
        !body.meeting_with &&
        !body.status &&
        !body.cancel_person &&
        !body.meeting_note;

      if (isEmpty && body.time_slot) {
        // Call create-slot API if these fields are empty
        const slotResponse = await axios.post(
          `${BASE_URL}/create-slot`,
          { time: body.time_slot },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (slotResponse?.data?.status === "1") {
          toast.success(slotResponse?.data?.message);

          setCallSlotsApi(!callSlotsApi);
          getMeetingData();
          setChange(!change);
        } else {
          toast.error(slotResponse?.data?.message);
          getMeetingData();
          setChange(!change);
        }
      } else {
        // Call create-meeting API if any of these fields are filled
        const response = await axios.post(`${BASE_URL}/create-meeting`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response?.data?.success === 1) {
          await axios.post(
            `${BASE_URL}/create-slot`,
            { time: data?.time_slot },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success(response?.data?.message);
          setCallSlotsApi(!callSlotsApi);
          getMeetingData();
          setChange(!change);
          //removed "Clear" from below
          setStatusName("");
          setStatusColor("");
        }
      }
    } catch (error) {
      setStatusColor("");
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  // const updateExistingMeeting = async (data) => {
  //   // console.log("ISKA BHI DATA BHT HAI:",data.status);

  //   const token = localStorage.getItem("token");
  //   let body = {
  //     meeting_id: data?._id,
  //     time_slot: data?.time_slot,
  //     status: statusName !== "Clear" ? statusName : data.status,
  //     new_person: data?.meeting_with,
  //     cancel_person: data?.cancel_person,
  //     // bg_color: data?.bg_color,
  //     meeting_date: date,
  //     meeting_note: data?.meeting_note,
  //   };
  //   try {
  //     const response = await axios.put(`${BASE_URL}/update-meeting`, body, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (response?.data?.success === 1) {
  //       await axios.post(
  //         `${BASE_URL}/create-slot`,
  //         { time: data?.time_slot },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  //       toast.success(response?.data?.message);
  //       getMeetingData();
  //       setChange(!change);
  //       setStatusName("Clear");
  //       setStatusColor("");
  //       // getProfileData();
  //       // setShowCity(false);
  //     }
  //   } catch (error) {
  //     setStatusColor("");
  //     if (error?.response?.data) {
  //       if (
  //         error.response.data.message === "Session expired please login again"
  //       ) {
  //         // Handle session expiration
  //         localStorage.removeItem("token");
  //         navigate("/");
  //         toast.error(error?.response?.data?.message);
  //       } else {
  //         toast.error(error?.response?.data?.message);
  //       }
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  const updateExistingMeeting = async (data) => {
    const token = localStorage.getItem("token");

    let body = {
      meeting_id: data?._id,
      time_slot: data?.time_slot,
      status:
        statusName !== "" && statusName !== "Clear"
          ? statusName
          : statusName === "Clear"
          ? ""
          : data.status === "Clear"
          ? ""
          : data.status,
      new_person: data?.meeting_with,
      cancel_person: data?.cancel_person,
      // bg_color: data?.bg_color,
      meeting_date: date,
      meeting_note: data?.meeting_note,
    };

    try {
      // Check if meeting_with, status, cancel_person, and meeting_note are all empty
      const isEmpty =
        !body.new_person &&
        (!body.status || body.status === "Clear") &&
        !body.cancel_person &&
        !body.meeting_note;

      if (isEmpty && body.time_slot) {
        // Call create-slot API if these fields are empty
        const slotResponse = await axios.post(
          `${BASE_URL}/create-slot`,
          { time: body.time_slot },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (slotResponse?.data?.status === "1") {
          toast.success(slotResponse?.data?.message);
          setCallSlotsApi(!callSlotsApi);
          getMeetingData();
          setChange(!change);
        } else {
          toast.error(slotResponse?.data?.message);
        }
      } else {
        const response = await axios.put(`${BASE_URL}/update-meeting`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response?.data?.success === 1) {
          await axios.post(
            `${BASE_URL}/create-slot`,
            { time: data?.time_slot },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success(response?.data?.message);
          setCallSlotsApi(!callSlotsApi);
          getMeetingData();
          setChange(!change);
          //removed "Clear" from below
          setStatusName("");
          setStatusColor("");
        }
      }
    } catch (error) {
      setStatusColor("");
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   getMeetingStatus();
  //   // eslint-disable-next-line
  // }, []);

  // const getMeetingStatus = async () => {
  //   const token = localStorage.getItem("token");
  //   // setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${BASE_URL}/get-active-status`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     // .finally(() => setIsLoading(false));
  //     //old
  //     // if (response?.data?.success === 1) {
  //     //   SetStatusData([
  //     //     ...response?.data?.status,
  //     //     { status_name: "Clear", status_color: "#FFFFFF" },
  //     //   ]);
  //     //   // toast.success(response?.data?.message);
  //     // }
  //     if (response?.data?.success === 1) {
  //       SetStatusData([
  //         ...response?.data?.status,
  //         { status_name: "", status_color: "" },
  //       ]);
  //       // toast.success(response?.data?.message);
  //     }
  //   } catch (error) {
  //     if (error?.response?.data?.message) {
  //       if (
  //         error.response.data.message === "Session expired please login again"
  //       ) {
  //         // Handle session expiration
  //         localStorage.removeItem("token");
  //         navigate("/");
  //         toast.error(error?.response?.data?.message);
  //       } else {
  //         toast.error(error?.response?.data?.message);
  //       }
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  return (
    <>
      <div className="  border" style={{ width: "fit-content" }}>
        <div className="color-picker-parent ">
          {/* <MeetingsTable/> */}
          {isLoading && <Spinner />}
          {tableData && !isLoading && (
            // removed this style from the following parent div : style={{ overflowX: "auto" }}
            <div className=" d-flex flex-row">
              <div
                className="d-flex flex-column"
                style={{
                  backgroundColor: `${divColor}`,
                  display: "inline-block",

                  textAlign: "center",
                  minWidth: "95px",

                  fontSize: "30px",
                  fontWeight: "800",
                  // marginTop: "85px",
                  marginBottom: "16px",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                }}
              >
                <div style={{ height: "6%", fontSize: "36px" }}>
                  {" "}
                  {date.add(-1, "day").format("ddd")}
                </div>
                <div
                  className="flex-grow-1"
                  style={{
                    // height: "94%",
                    writingMode: "vertical-rl",
                    transform: "rotate(360deg)",
                    paddingRight: "20px",
                  }}
                >
                  {date.add(-1, "day").format("DD-MMM-YYYY")}
                </div>
              </div>

              <table className="table">
                <thead>
                  <tr style={{}}>
                    {tableData?.map((agent, index) => (
                      <React.Fragment key={index}>
                        <th
                          colSpan="3"
                          style={{
                            minWidth: "300px",
                            backgroundColor: agent?.bg_color,
                            fontSize: "22px",
                            padding: "10px",
                            textAlign: "center",

                            // fontWeight:"600"
                          }}
                          className="custom-border"
                        >
                          {agent.agent_name}
                          {agent.meetings.length === 0 &&
                            !date.add(-1, "day").isBefore(today, "day") && (
                              <span
                                className="position-absolute plus-icon pointer"
                                style={{ zIndex: "10" }}
                                onClick={() => handleAdddd(agent.agent_id)}
                              >
                                <img alt="" src={PlusIcon} />
                              </span>
                            )}
                        </th>
                        {index !== tableData?.length - 1 && (
                          <th
                            style={{ width: "20px" }}
                            className="seperator"
                          ></th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {[
                  ...Array(
                    Math.max(...tableData.map((agent) => agent.meetings.length))
                  ),
                ].map((_, index) => ( */}
                  {Array?.from({
                    length: Math?.max(
                      ...tableData?.map((agent) => agent.meetings.length)
                    ),
                  })?.map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {tableData?.map((agent, agentIndex) => (
                        <React.Fragment key={`${agentIndex}-${rowIndex}`}>
                          {agent.meetings[rowIndex] ? (
                            <>
                              <td
                                style={{ width: "90px" }}
                                className="px-0 custom-border menu-container specific-column"
                                onMouseEnter={() =>
                                  handleRowHover(agent.meetings[rowIndex]._id)
                                }
                                onMouseLeave={handleRowLeave}
                                onDoubleClick={() =>
                                  handleDouble(agent.meetings[rowIndex]._id)
                                }
                              >
                                <small
                                  className="d-block  px-2"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "16px",
                                  }}
                                >
                                  {editingMeetingTextId ===
                                  agent.meetings[rowIndex]._id ? (
                                    <input
                                      name="time_slot"
                                      type="text"
                                      className="edit-able-inputs "
                                      value={editingMeetingText?.time_slot}
                                      onChange={handleInputChange}
                                      onKeyPress={handleKeyPress}
                                      placeholder="Time"
                                      style={{ width: "88px" }}
                                    />
                                  ) : (
                                    agent.meetings[rowIndex].time_slot
                                  )}
                                </small>

                                {/* Delete Button */}
                                <div
                                  className="menu-button"
                                  onClick={() => {
                                    handleDeleteTimeSlot(
                                      agent.meetings[rowIndex]._id,
                                      agent.meetings[rowIndex].time_slot
                                    );
                                  }}
                                >
                                  <img
                                    alt="Menu"
                                    src={delIcon}
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                </div>

                                {/* Dropdown Menu */}
                                {/* <div className="menu">
                                  <div
                                    className="menu-item"
                                    onClick={() => {
                                      console.log(
                                        "HERE IS TIME SLOT TO BE DELETED:",
                                        agent.meetings[rowIndex].time_slot
                                      );
                                      handleDeleteTimeSlot(
                                        agent.meetings[rowIndex]._id,
                                        agent.meetings[rowIndex].time_slot
                                      );
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div> */}
                              </td>
                              <td
                                className="px-0  custom-border "
                                onMouseEnter={() =>
                                  handleRowHover(agent.meetings[rowIndex]._id)
                                }
                                onMouseLeave={() => {
                                  if (!isEditingTooltip) {
                                    handleRowLeave();
                                    setShowTooltip(false);
                                    setIsTooltipActive(false);
                                  }
                                }}
                                onDoubleClick={() =>
                                  handleDouble(agent.meetings[rowIndex]._id)
                                }
                                style={{
                                  position: "relative",
                                  backgroundColor:
                                    editingMeetingTextId ===
                                    agent.meetings[rowIndex]._id
                                      ? statusColor
                                      : agent.meetings[rowIndex].bg_color,
                                }}
                              >
                                {editingMeetingTextId ===
                                agent.meetings[rowIndex]._id ? (
                                  <small
                                    className={`d-block text-white `}
                                    style={{
                                      backgroundColor: `${
                                        editingMeetingTextId ===
                                        agent.meetings[rowIndex]._id
                                          ? agent.meetings[rowIndex].bg_color
                                          : statusColor
                                      }`,
                                      fontWeight: "600",
                                    }}
                                  >
                                    <input
                                      onChange={handleInputChange}
                                      onDoubleClick={(e) => e.stopPropagation()}
                                      name="meeting_with"
                                      id="meeting_with"
                                      type="text"
                                      placeholder="Name"
                                      className={`edit-able-inputs-name px-2 `}
                                      value={editingMeetingText?.meeting_with}
                                      style={{
                                        backgroundColor: `${
                                          editingMeetingTextId ===
                                          agent.meetings[rowIndex]._id
                                            ? statusColor
                                            : agent.meetings[rowIndex].bg_color
                                        }`,
                                        color: `#000000`,
                                      }}
                                      autoComplete="new-meeting-with"
                                      // autoFocus
                                      onKeyPress={handleKeyPress}
                                    />
                                  </small>
                                ) : (
                                  <small
                                    className={`d-block  px-2 uu truncated uppercase-text`}
                                    title={
                                      agent?.meetings[rowIndex]?.meeting_with
                                    }
                                    style={{
                                      // backgroundColor: `${agent.meetings[rowIndex].bg_color}`,
                                      fontWeight: "700",
                                      color: `#000000`,
                                      textTransform: "uppercase",
                                      // position:"relative"
                                      // fontSize: agent?.meetings[rowIndex]?.meeting_note?"40px":"15px"
                                    }}
                                  >
                                    &nbsp;
                                    {agent?.meetings[rowIndex]?.meeting_with}
                                  </small>
                                )}

                                {/* {agent?.meetings[rowIndex]?.meeting_note && (
                                  <img
                                    alt="icon"
                                    onClick={() => {
                                      setNote(
                                        agent?.meetings[rowIndex]?.meeting_note
                                      );
                                      setAgentId(
                                        agent.meetings[rowIndex].agent_id
                                      );
                                      setMeetingId(
                                        agent.meetings[rowIndex]._id
                                      );
                                      setTimeSlot(
                                        agent.meetings[rowIndex].time_slot
                                      );

                                      setShowNoteModal(true);
                                      setAnchorEl(null);
                                    }}
                                    src={arrowHead}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      transform: "rotate(-37deg)", 
                                      position: "absolute", 
                                      top: -7, 
                                      right: -8,
                                      cursor: "pointer",
                                    }}
                                    title={
                                      agent?.meetings[rowIndex]?.meeting_note
                                    }
                                  />
                                  
                                )} */}
                                {agent?.meetings[rowIndex]?.meeting_note && (
                                  <span
                                    className="pic-span"
                                    style={{
                                      position: "relative",
                                      // display: "inline-block",
                                      height: "0px",
                                    }}
                                    onMouseEnter={() => {
                                      setShowTooltip(true);
                                      setTooltipContent(
                                        agent?.meetings[rowIndex]?.meeting_note
                                      );
                                      handleMouseEnter(
                                        agent?.meetings[rowIndex]?._id,
                                        agent?.meetings[rowIndex]?.meeting_note
                                      );
                                    }}
                                    onMouseLeave={() => {
                                      handleMouseLeave();
                                      // if (!isTooltipActive)
                                      //   setShowTooltip(false);
                                    }}
                                  >
                                    <img
                                      alt="icon"
                                      onClick={() => {
                                        setNote(
                                          agent?.meetings[rowIndex]
                                            ?.meeting_note
                                        );
                                        setAgentId(
                                          agent.meetings[rowIndex].agent_id
                                        );
                                        setMeetingId(
                                          agent.meetings[rowIndex]._id
                                        );
                                        setTimeSlot(
                                          agent.meetings[rowIndex].time_slot
                                        );

                                        setShowNoteModal(true);
                                        setAnchorEl(null);
                                      }}
                                      src={arrowHead}
                                      style={{
                                        height: "15px",
                                        width: "15px",
                                        transform: "rotate(-41deg)",
                                        position: "absolute",
                                        top: -27,
                                        right: -204,
                                        cursor: "pointer",
                                      }}
                                    />
                                    {/* Custom Tooltip */}

                                    {isTooltipVisible(
                                      agent?.meetings[rowIndex]?._id
                                    ) && (
                                      <div
                                        className="custom-tooltip"
                                        tabIndex={0}
                                        onMouseEnter={() =>
                                          setIsTooltipActive(true)
                                        }
                                        onMouseLeave={() =>
                                          setIsTooltipActive(false)
                                        }
                                        onClick={() =>
                                          setIsEditingTooltip(true)
                                        }
                                        // onKeyDown={handleTooltipEnterPress}
                                      >
                                        {isEditingTooltip ? (
                                          <input
                                            type="text"
                                            value={tooltipContent}
                                            maxLength="199"
                                            autoFocus
                                            onChange={(e) => {
                                              setTooltipContent(e.target.value);
                                              setNote(
                                                agent?.meetings[rowIndex]
                                                  ?.meeting_note
                                              );
                                              setAgentId(
                                                agent.meetings[rowIndex]
                                                  .agent_id
                                              );
                                              setMeetingId(
                                                agent.meetings[rowIndex]._id
                                              );
                                              setTimeSlot(
                                                agent.meetings[rowIndex]
                                                  .time_slot
                                              );
                                            }}
                                            onBlur={saveTooltipContent}
                                            onKeyDown={handleTooltipEnterPress}
                                            className="tooltip-edit-input"
                                            style={{
                                              width: "200px",
                                              padding: "5px",
                                              border: "none",
                                              borderRadius: "4px",
                                              background: "#fff",
                                              color: "#000",
                                            }}
                                          />
                                        ) : (
                                          tooltipContent
                                        )}
                                      </div>
                                    )}
                                  </span>
                                )}
                              </td>
                              {/* <td
                                className="px-0  custom-border "
                                title={agent?.meetings[rowIndex]?.meeting_note}
                                onMouseEnter={() =>
                                  handleRowHover(agent.meetings[rowIndex]._id)
                                }
                                onMouseLeave={handleRowLeave}
                                onDoubleClick={() =>
                                  handleDouble(agent.meetings[rowIndex]._id)
                                }
                              >
                                {editingMeetingTextId ===
                                agent.meetings[rowIndex]._id ? (
                                  <small
                                    className={`d-block text-white px-2`}
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    <input
                                      onChange={handleInputChange}
                                      name="meeting_note"
                                      id="meeting_note"
                                      type="text"
                                      placeholder="Meeting Note"
                                      className={`edit-able-inputs-name ${
                                        editingMeetingText?.bg_color !==
                                          "#FFFFFF" &&
                                        "meeting-person-placeholder"
                                      }`}
                                      value={editingMeetingText?.meeting_note}
                                      style={{
                                        color: "#6c757d",
                                      }}
                                      autoComplete="new-meeting-with"
                                      onKeyPress={handleKeyPress}
                                    />
                                  </small>
                                ) : (
                                  <small
                                    className={`d-block  px-2 uu truncated`}
                                    style={{
                                      fontWeight: "600",
                                      color: "#6c757d",
                                    }}
                                    title={
                                      agent?.meetings[rowIndex]?.meeting_note
                                    }
                                  >
                                    &nbsp;
                                    {agent?.meetings[rowIndex]?.meeting_note}
                                  </small>
                                )}
                              </td> */}

                              <td
                                className="px-0  custom-border"
                                onMouseEnter={() =>
                                  handleRowHover(agent.meetings[rowIndex]._id)
                                }
                                onMouseLeave={handleRowLeave}
                                onDoubleClick={() =>
                                  handleDouble(agent.meetings[rowIndex]._id)
                                }
                              >
                                <small
                                  className="d-block text-muted px-2 text-decoration-line-through position-relative line-through-2"
                                  style={{
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {editingMeetingId ===
                                    agent.meetings[rowIndex]._id &&
                                    editingMeetingTextId !==
                                      agent.meetings[rowIndex]._id && (
                                      // !value.isBefore(today, "day") &&
                                      // Render edit button if meeting is being edited
                                      <>
                                        <div>
                                          <span
                                            className="fw-semibold position-absolute pointer rounded d-flex justify-content-center align-items-center"
                                            id="fade-button"
                                            aria-controls={
                                              open ? "fade-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                            onClick={handleClick}
                                            style={{
                                              backgroundColor: "#ff9090",
                                              width: "20px",
                                              height: "20px",
                                              textAlign: "center",
                                              right: "5px",
                                              top: "2px",
                                            }}
                                          >
                                            <img
                                              className=""
                                              alt=""
                                              src={DownArrow}
                                            />
                                          </span>

                                          <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                              "aria-labelledby": "fade-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            TransitionComponent={Fade}
                                          >
                                            {editingMeetingId ===
                                              agent.meetings[rowIndex]._id &&
                                              !date
                                                .add(-1, "day")
                                                .isBefore(today, "day") && (
                                                <MenuItem
                                                  // className="fw-bold"
                                                  onClick={() =>
                                                    handleAdd(
                                                      agent.meetings[rowIndex]
                                                        ._id
                                                    )
                                                  }
                                                >
                                                  Create
                                                </MenuItem>
                                              )}
                                            <MenuItem
                                              onClick={() =>
                                                handleDouble(
                                                  agent.meetings[rowIndex]._id
                                                )
                                              }
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                handleDelete(
                                                  agent.meetings[rowIndex]._id,
                                                  agent.meetings[rowIndex]
                                                    .time_slot
                                                );
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                            {editingMeetingId ===
                                              agent.meetings[rowIndex]._id &&
                                              !date
                                                .add(-1, "day")
                                                .isBefore(today, "day") && (
                                                <MenuItem
                                                  // className="fw-bold"
                                                  // onClick={() =>
                                                  //   handleAdd(
                                                  //     agent.meetings[rowIndex]
                                                  //       ._id
                                                  //   )
                                                  // }
                                                  onClick={() => {
                                                    setNote(
                                                      agent?.meetings[rowIndex]
                                                        ?.meeting_note
                                                    );
                                                    setAgentId(
                                                      agent.meetings[rowIndex]
                                                        .agent_id
                                                    );
                                                    setMeetingId(
                                                      agent.meetings[rowIndex]
                                                        ._id
                                                    );
                                                    setTimeSlot(
                                                      agent.meetings[rowIndex]
                                                        .time_slot
                                                    );

                                                    setShowNoteModal(true);
                                                    setAnchorEl(null);
                                                  }}
                                                >
                                                  {agent.meetings[rowIndex]
                                                    .meeting_note
                                                    ? "Update Note"
                                                    : "Add Note"}
                                                </MenuItem>
                                              )}
                                          </Menu>
                                        </div>
                                      </>
                                    )}
                                  {editingMeetingTextId ===
                                  agent.meetings[rowIndex]._id ? (
                                    <>
                                      <span
                                        className="fw-semibold position-absolute pointer rounded d-flex justify-content-center align-items-center"
                                        style={{
                                          backgroundColor: "#ff9090",
                                          width: "20px",
                                          height: "20px",
                                          textAlign: "center",
                                          right: "5px",
                                          top: "2px",
                                        }}
                                        // onMouseDown={(e) => e.preventDefault()}  // Prevent mouse down
                                        // onDoubleClick={(e) => e.stopPropagation()}  // Prevent double click
                                        onClick={handleSave}
                                      >
                                        <img
                                          className=""
                                          alt=""
                                          src={checkMarkArrow}
                                        />
                                      </span>
                                      <input
                                        name="cancel_person"
                                        type="text"
                                        className="edit-able-inputs-name pe-4  text-muted text-primary text-decoration-line-through"
                                        value={
                                          editingMeetingText?.cancel_person
                                        }
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Name"
                                        autoComplete="new-cancel-person"
                                        // onMouseDown={(e) => e.preventDefault()}
                                        onDoubleClick={(e) =>
                                          e.stopPropagation()
                                        }
                                        // autoFocus
                                        // value={agent.meetings[rowIndex].cancelled}
                                      />
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        maxWidth: "170px",
                                        position: "relative",
                                      }}
                                    >
                                      <small
                                        className="overflow-here"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          textTransform: "uppercase",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          width: "95%",
                                          overflow: "hidden",
                                        }}
                                        title={
                                          agent.meetings[rowIndex].cancel_person
                                        }
                                      >
                                        {agent.meetings[rowIndex].cancel_person}
                                      </small>
                                    </div>
                                  )}
                                </small>
                              </td>
                              {agentIndex !== tableData?.length - 1 && (
                                <td
                                  style={{ width: "20px" }}
                                  className="seperator"
                                ></td>
                              )}
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td
                                style={{ width: "20px" }}
                                className="seperator"
                              ></td>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <NoteModal
        showNoteModal={showNoteModal}
        onHide={() => {
          setShowNoteModal(false);
        }}
        handleSaveNote={handleAddNote}
        agentId={agentId}
        meetingId={meetingId}
        timeSlot={timeSlot}
        date={date}
        notee={notee}
      />
    </>
  );
}
